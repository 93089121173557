import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Contacto"
        description="Esta es nuestra pagina de contacto, rellene el formulario con la duda que tenga y asi podremos asesorarle de una manera profesional"
      />
      <section className="contact-page">
        <article className="contact-form">
          <h3>Contáctanos</h3>
          <form action="https://formspree.io/mayporje" method="POST">
            <div className="form-group">
              <input
                type="text"
                placeholder="nombre y apellidos"
                name="nombre"
                className="form-control"
              />
              <input
                type="email"
                placeholder="E-mail"
                name="email"
                className="form-control"
              />
              <input
                type="number"
                placeholder="teléfono"
                name="telefono"
                className="form-control"
              />
              <textarea
                name="mensaje"
                rows="5"
                placeholder="describa su consulta"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              Enviar
            </button>
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact
